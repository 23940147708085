<template>
  <standard-page :title="processDefinition.name" v-if="processDefinition">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'provenances-registries'}">Registries</router-link>
      </span>
      <span v-if="registry">
        <router-link :title="registry.name" :to="{ name: 'processdefinitions'}">Registry: {{registry.name | truncate(10, '.....')}}</router-link>
      </span>
      <span v-if="processDefinition">
        <router-link :title="processDefinition.name" :to="{ name: 'process-definition-steps'}">Process Definition: {{processDefinition.name | truncate(10, '.....')}}</router-link>
      </span>
      <span>New Step</span>
    </template>
    <template v-slot:content>
      <div class="az-content-label mg-b-5">New Process Step</div>
      <div class="mg-b-20" v-html="$DEFINITIONS.provenance.newProcessDefinitionStep"></div>
        <div class="wizard clearfix" style="width: 1050px">
            <div class="steps clearfix">
                <ul>
                    <li class="head d-inline-flex"><span :class="{active: activeTab === 0}" class="number">1</span> <span :class="{active: activeTab === 0}" class="title">Basic Details</span></li>
                    <li class="head d-inline-flex"><span :class="{active: activeTab === 1}" class="number">2</span> <span :class="{active: activeTab === 1}" class="title">Definition Attributes</span>
                    <li class="head d-inline-flex"><span :class="{active: activeTab === 2}" class="number">3</span> <span :class="{active: activeTab === 2}" class="title">Attestors</span>
                    </li>
                </ul>
            </div>
            <div class="content clearfix">
                <form data-vv-scope="step1">
                    <section class="body current" v-if="activeTab === 0">
                         <div class="row row-xs align-items-center mg-b-20">
                                <div class="col-md-2">
                                    <label class="form-label mg-b-0">Step Name</label>
                                </div>
                                <div class="col-md-8 mg-t-5 mg-md-t-0">
                                    <input maxlength="100" class="form-control rounded" placeholder="Enter name" type="text" v-model="process_definition_step.name">
                                    <span class="mg-l-5 tx-13 text-muted">The name should not exceed more than 100 characters</span>
                                </div>
                            </div>
                            <div class="row row-xs align-items-center mg-b-20">
                                <div class="col-md-2">
                                    <label class="form-label mg-b-0">Description</label>
                                </div>
                                <div class="col-md-8 mg-t-5 mg-md-t-0">
                                    <textarea class="form-control rounded" placeholder="Enter Description" rows="3" type="text" v-model="process_definition_step.description"></textarea>
                                </div>
                            </div>
                    </section>
                </form>
                <form data-vv-scope="step2">
                    <section class="body current" v-if="activeTab === 1">
                        <process-definition-step-attributes :registryid="registryid" :processdefinitionid="processdefinitionid" :attributes="process_definition_step.attributes" :submitted="submitted" :addRow="addAttributeRow" :removeRow="removeAttributeRow"></process-definition-step-attributes>
                    </section>
                </form>
                <form data-vv-scope="step3">
                    <section class="body current" v-if="activeTab === 2">
                            <div class="row row-xs mt-2" v-for="(row, index) in process_definition_step.attestors">
                                <div class="col-md-2">
                                    <label class="form-label mg-b-0">Attestor</label>
                                </div>
                                <div class="col-md-3 mg-t-5 mg-md-t-0">
                                    <multiselect :options="catalogs" :selectLabel="''" @input="chooseCatalog(index)" class="form-control rounded" data-vv-as="id" id="catalog_id" label="Catalog" name="catalog_id" open-direction="bottom" placeholder="Select Catalog" v-model="row.catalog" v-validate="'required'">
                                        <template slot="singleLabel" slot-scope="props">
                                            {{props.option.name}}
                                        </template>
                                        <template slot="option" slot-scope="props">
                                            <div class="option__desc">
                                                <h6>{{ props.option.name | capitalize }}</h6>
                                                <span>{{ props.option.catalog }}</span>
                                            </div>
                                        </template>
                                    </multiselect>
                                </div>
                                <div class="col-md-3 mg-t-5 mg-md-t-0">
                                    <multiselect :options="dids" :selectLabel="''" @input="chooseDid(index)" class="form-control rounded" data-vv-as="id" id="_did" label="DID" name="did" open-direction="bottom" placeholder="Select DID" v-model="row.did" v-validate="'required'">
                                        <template v-if="props.option.did" slot="singleLabel" slot-scope="props">
                                            {{props.option.did | truncate(15, '....')}}
                                        </template>
                                        <template slot="option" slot-scope="props">
                                            <div class="option__desc">
                                                <h6>{{props.option.short_name}}</h6>
                                                <span v-if="props.option.did">{{ props.option.did | truncate(28, "...." )}}</span>
                                            </div>
                                        </template>
                                    </multiselect>
                                </div>
                                <div class="col-md-2 mg-t-5 mg-md-t-0">
                                    <input class="form-control rounded" placeholder="Enter name" style="height: 42px" type="text" v-model="row.short_name">
                                </div>
                                <div class="col-md mg-t-10 mg-md-t-0" v-if="process_definition_step.attestors.length > 1">
                                    <a href="javascript:void(0)" @click="removeRow" class="btn btn-block btn-danger rounded"><i class="fa fa-trash-alt"></i></a>
                                </div>
                                <div class="col-md mg-t-10 mg-md-t-0">
                                    <a href="javascript:void(0)" v-if="index === process_definition_step.attestors.length-1" @click="addRow" class="btn btn-block btn-success rounded" type="button"><i class="fa fa-plus-square"></i></a>
                                </div>
                            </div>
                    </section>
                </form>
            </div>
            <div class="actions clearfix">
                <ul>
                    <li>
                        <button :disabled="activeTab === 0" @click="prev" class="btn btn-indigo rounded">Previous</button>
                    </li>
                    <li>
                        <button @click="next" aria-hidden="false" class="btn btn-indigo rounded" v-if="activeTab === 0 || activeTab === 1">Next</button>
                        <button @click="createProcessDefinitionStep" class="btn btn-orange rounded" v-if="activeTab === 2">Submit</button>
                    </li>
                </ul>
            </div>
        </div>
    </template>
  </standard-page>
</template>

<script>
import EventBus from "@/event-bus";
import Multiselect from 'vue-multiselect';
import ProcessDefinitionStepAttributes from '@/components/protected/provenances/components/ProcessDefinitionStepAttributes';

export default {
  name: "NewProcessDefinitionStep",
  props: ['registryid', 'processdefinitionid'],
  components: { Multiselect, ProcessDefinitionStepAttributes },
  data() {
    return {
      submitted: false,
      registry: null,
      processDefinition: null,
      catalogs: [],
      dids: [],

      process_definition_step: {
        name: null,
        description: null,
        order: 0,
        attestors: [{
          catalog: null,
          short_name: null,
          did: null,
        }],
        attributes: [
          {
            name: null,
            type: "Text",
            required: true,
            attribute_group: null,
            repeatable: false,
            private: false,
          }
        ]

      },
        activeTab: 0,
        stepOneSubmitted: false,
        stepTwoSubmitted: false,
        stepThreeSubmitted: false,

    };
  },
  mounted() {
    this.init();
  },
  methods: {
    addAttributeRow() {
      this.process_definition_step.attributes.push({
        name: null,
        type: "Text",
        required: true,
          attribute_group: null,
          repeatable: false,
          private: false,
          definition_ref: null
      });
    },
    removeAttributeRow(index) {
      this.process_definition_step.attributes.splice(index, 1);
    },
    async init() {
      await this.getRegistry();
      await this.getProcessDefinition();
      await this.getCatalogs();
    },
    async getRegistry() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}`);
        this.registry = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Registry", "Error");
      } finally {

      }
    },
    async getProcessDefinition() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}`);
        this.processDefinition = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Process Definition", "Error");
      } finally {

      }
    },
    async getCatalogs() {
      try {
        let reply = await this.$identity.get('identity/catalogs', { params: { page: 0, per_page: 30 } });
        this.catalogs = reply.data ? reply.data.catalogs : [];
      } catch (e) {
        this.$toastr.e("Error retrieving Catalogs", "Error");
      }
    },
    chooseCatalog(index) {
      this.dids = [];
      this.getDids(index);
    },
    chooseDid(i) {
      this.process_definition_step.attestors[i].short_name = this.process_definition_step.attestors[i].did.short_name ? this.process_definition_step.attestors[i].did.short_name : null;
    },
    async getDids(i) {
      try {
        let reply = await this.$identity.get(`/identity/catalogs/${this.process_definition_step.attestors[i].catalog.catalog}/dids`, {
          params: {
            page: 0,
            per_page: 30
          }
        });
        this.dids = reply.data ? reply.data.dids : [];

      } catch (e) {
        this.$toastr.e("Error retrieving DIDs", "Error");
      }
    },
    addRow() {
      this.process_definition_step.attestors.push({
        short_name: null,
        did: null,
      });
    },
    removeRow(index) {
      this.process_definition_step.attestors.splice(index, 1);
    },
    async createProcessDefinitionStep() {
      if (this.process_definition_step.name) {
        this.process_definition_step.attestors = this.process_definition_step.attestors.filter(a => a.did !== null);
        this.process_definition_step.attestors.forEach(a => a.did = a.did.did);
        this.process_definition_step.attributes = this.process_definition_step.attributes.filter(a => a.name !== null).map(attr=> ({
            ...attr,
            definition_ref: Number(attr.definition_ref),
            attribute_group: attr.attribute_group !== null && attr.attribute_group.length >= 0 && attr.attribute_group.replace(/\s/g, '').length === 0 ? null : attr.attribute_group
        }));
        try {
          EventBus.$emit('openLoader');
          await this.$provenance.post(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps`, this.process_definition_step);
          this.reset();
          await this.$router.push({ name: 'process-definition-steps' });
        } catch (e) {
          this.$toastr.e("Process Definition Step creation failed", 'Error');
        } finally {
          EventBus.$emit('closeLoader');
        }
      } else {
        this.$toastr.e("Please provide a name first!", 'Failed');
      }
    },
    reset() {
      this.process_definition_step = {
        name: null,
        description: null,
        attestors: [{
          catalog: null,
          short_name: null,
          did: null,
        }],
        attributes: [
          {
            "name": null,
            "type": "Text",
            "required": true,
              attribute_group: null,
              repeatable: false,
              private: false,
          }
        ]
      };
    },
      prev() {
          this.activeTab--;
      },
      next() {
        if(this.activeTab === 0){
            this.stepOneSubmitted = true;
        }else if (this.activeTab === 1){
            this.stepTwoSubmitted = true;
        }
          this.activeTab++;
      }
  }
}
</script>

<style scoped>

    .head {
        align-items: center;
    }

    .head .number {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        background-color: #b4bdce;
        line-height: 2.1;
        display: block;
        color: #ffffff;
        text-align: center;
    }

    @media only screen and (max-width: 600px) {
        .head .number {
            border-radius: 100%;
            width: 40px;
            height: 40px;
            background-color: #b4bdce;
            line-height: 2.1;
            display: block;
            color: #ffffff;
            text-align: center;
        }
    }

    @media only screen and (max-width: 768px) {
        .head .number {
            border-radius: 100%;
            width: 40px;
            height: 40px;
            background-color: #b4bdce;
            line-height: 3;
            display: block;
            color: #ffffff;
            text-align: center;
        }
    }

    @media only screen and (min-width: 769px) {
        .head .number {
            border-radius: 100%;
            width: 40px;
            height: 40px;
            background-color: #b4bdce;
            line-height: 3.1;
            display: block;
            color: #ffffff;
            text-align: center;
        }
    }

    @media only screen and (max-width: 1366px) {
        .head .number {
            border-radius: 100%;
            width: 40px;
            height: 40px;
            background-color: #b4bdce;
            line-height: 3;
            display: block;
            color: #ffffff;
            text-align: center;
        }
    }

    .head .number.active {
        background-color: #6675fa !important;
    }

    .head .title.active {
        color: #6675fa !important;
    }
</style>